<template>
  <div class="about"><h1>About</h1></div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'about',
  components: {
  }
}
</script>
